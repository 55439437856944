.main_about {
    width: 90%;
    max-width: 1366px;
    padding-bottom: 2rem;
    margin: 0 auto;
}

.about_content_holder {
  display: flex;
  justify-content: space-between;
}

.about_left {
  width: 40%;
  position: relative;
}

.about_right {
  width: 60%;
  margin: 0 auto;
  align-items: start;
}

.creative_butterfly {
  margin-top: -6rem;
  height: 500px;
  opacity: .2;
  z-index: 2;
}

/* .butterfly_bg {
  height: 80%;
  position: absolute;
  margin-top: -4rem;
  opacity: .6;
  left: 7rem;
} */

.about_title {
    font-size: 44px;
    font-weight: bold;
    opacity: 1;
    text-transform: uppercase;
}



.our_story_para {
    width: 100%;
    margin: 3rem 0;
    opacity: .5;
    font-size: 24px;
    text-align: start;
}

.our_story_para:hover {
    transition: ease-in ease-out 1.5s;
    opacity: .8;
}

.about_us_btn_holder {
 display: flex;
  width:  auto;
}

.about_us_btn {
    width: 160px;
    padding: 15px 20px;
    color: white;
    border-radius: 30px;
    background: linear-gradient(black, black) padding-box, border-box var(--mainBg);
    border: 2px solid transparent;
    cursor: pointer;  
    font-size: 1em;
}

.about_us_btn:hover {
  box-shadow: 1px 1px 5px var(--thirdColor);
}

.model_wrapper {
  position: fixed;
  top: 20%;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  z-index: 999;
}

.more_about_us {
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 90%;
  margin: 1.5rem auto;
}

.more_about_us .close_me_btn {
  background: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: none;
  color: #252525;
  transition: ease-in .1s;
}

.more_about_us .close_me_btn:hover {
  /* transform: rotate(90deg); */
  color: #086894;

}

.about_more_details_pop {
  width: 90%;
  margin: 0 auto;
  overflow-y: scroll;
  height: 100%;
}

.about_popup_paragraph {
  color: #000;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 6rem 1rem 8rem 0;
}


@media (max-width: 1200px) {
  .about_content_holder {
    padding-top: 5rem;
    .about_left{
      width: 90%;
      margin: 0 auto;
    }
    .about_right{
      width: 90%;
      margin: 0 auto;
    }}
}

@media (max-width: 960px) {
  .about_content_holder {
    flex-direction: column;
  }

  .about_title {
    text-align: center;
  }

  .our_story_para {
    text-align: center;
  }

  .about_us_btn_holder {
    align-items: center;
    justify-content: center;
  }
  
  .about_left{
      width: 100%;
      margin: 0 auto;
    }
    .about_right{
      width: 100%;
      margin: 0 auto;
    }
}


  @media (max-width: 640px) {
    .creative_hand {
      height: 100%;
    }
    .hand_bg {
      margin-left: 20px;
      height: 20rem;
    }

    .our_story_para {
      text-align: center;
    }
    
    .about_us_btn_holder {
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (max-width: 480px) {

    .main_about {
      height: 76vh;
    }

    .creative_butterfly {
      height: 350px;
      margin-left: -2rem;
      opacity: .5;
      margin-top: -2rem;
    }

    .about_title {
      font-size: 1.5rem;
    }

    .our_story_para {
      font-size: 1rem;
      line-height: 1.3rem;
      opacity: .8;
    }

    .about_us_btn {
      padding: 8px 20px;
      width: 150px;
    }
    

    
  }


