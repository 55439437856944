
/* .container{
    margin-top: 20px ;
    margin-bottom: 20px ;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: margin-left 0.3s ease;
    margin-left: 25px;
    background:  rgb(249, 249, 249, 0.7)
} */

.top{
    background:  #1c1c1c;
}
 .expanded-topbar {

    display: flex;
    justify-content: space-between;
    margin-top: 20px ;
    margin-bottom: 20px ;
    margin-left: 25px;
  }

  .icons{
    display: flex;
    margin-right: 20px;
  }
  .css-dip3t8 {
    background-color:#1c1c1c !important ;
  }
  .css-1wvake5 {
    border-right-width: 0px !important;
  }
  .menu-item{
    color: white !important;
  }
  .menu-item:hover {
    background-color:white !important;
    color:black !important;
  }