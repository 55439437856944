.main_blogs {
    width: 90%;
    max-width: 1366px;
    padding: 2.1rem 0;
    margin: 0 auto;
}

.blogs_title {
    font-size: 44px;
    font-weight: bold;
    opacity: .2;
    text-align: center;
    text-transform: uppercase;
}

.blogs_title:hover {
    transition: ease-in ease-out 1.5s;
    opacity: .7;
}

.gradient-border::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    border-radius: 1rem; /* Adjust based on your rounded-2xl class */
    padding: 2px; /* Adjust border thickness */
    background: linear-gradient(black, black) padding-box, border-box var(--mainBg);
  }

  .gradient-border > p {
    position: relative;
    z-index: 1;
  }
  
  .gradient-textss {
    background: linear-gradient(--var(mainBg));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

