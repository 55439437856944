.about_main_about_section {
    width: 90%;
    max-width: 1366px;
    margin: 2rem auto;
    
}

.about_section_heading {
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
    color:#fff ;
    text-align: center;
    padding: 2rem 0;
}

.about_section_paragraph {
    font-size: 1.2rem;
    font-weight: 400;
    text-align: center;
    margin: 1rem 0;
}

.about_our_team {
    background: #ffffff13;
    border-radius: 1.5rem;
    height: 70vh;
    margin: 3rem 0;

}

.about_team_title {
    margin: 1rem 0;
    text-align: center;
}

.about_team_details_container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    margin: 1.5rem 0;
}

.about_team_items {
    /* background: #ffffff12; */
    border-radius: .8rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team_image {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-size: cover;
    object-fit: cover;
    box-shadow: 0 0 6px #000;
}

.about_team_name {
    margin-top: .8rem;
}