.navbar_main {
    width: 100%;
    margin: auto;
    padding-inline: 60px;
    background-color: black;
    font-size: 1rem;
    padding-block: 12px;
    background-clip: padding-box;
    backdrop-filter: blur(6px);
    background-color: rgba(93, 93, 93, 0.1);
    
}

.nav_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav_items {
    display: flex;
    justify-content: center;
    gap: 30px;
}

#brand_logo {
    height: 30px;
}

/* .nav_items a {
    padding: 0 1em;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 500;
    color:#CDCDCD;
} */

/* .nav_items a:hover {
    color: white;
} */

/* .nav_items .current_page {
    color:#707070 ;
} */

.main_btn {
    text-decoration: none;
    padding: .6rem 1.2rem;
    border: 1px solid #25d365;
    display: flex;

    color: rgb(255, 255, 255);
    border-radius: 30px;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}

.whatsapp_icon {
    margin-right: .2rem;
}

.main_btn:hover {
    background: #25d365;
}

/*--------------------------- Responsive Starts From Here --------------------------------------------*/
.lab_navbar_menu {
    display: none;
    position: relative;
}

.lab_navbar_menu img {
    cursor: pointer;
}

/* .drag1 {
    height: 4px;
    width: 30%;
    background: linear-gradient(300deg, #e10b7a 0%, #652D90 49.48%, #1C85C7 100%);
    position: absolute;
    top: 1rem;
    border-radius: 1rem;
} */
/* .lab_menu_container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:rgb(255, 0, 0); 
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    position: fixed;
    width: 100%;
    top: 20rem;
    left: 0;
    bottom: 0;
    z-index: 99;
} */

.no-scroll {
    overflow: hidden;
  }
  
.lab_menu_links {
    display: flex;
    flex-direction: column;
    text-align: center; 
}

.lab_menu_links hr {
    border-radius: 2px;
}

.lab_menu_container a {
    text-decoration: none;
    color: #474747;
    padding: 10px 0;
    font-weight: 600;
    line-height: 2.8rem;
}

.lab_menu_container a:hover {
    font-weight: 700;
    color: #000;
}

.manu_btn {
    display: none;
}

@media screen and (max-width: 1023px) {
    .main_btn {
        display: none;
    }
        .nav_items {
        display: none;
    }
    .lab_navbar_menu {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width: 800px) {

    .main_btn {
        display: none;
    }
    .lab_navbar_menu {
        display: flex;
        justify-content: space-between;
    }
}

@media screen and (max-width: 700px) {
    .navbar_main {
        width: 100%;
        padding-inline: 10px;
    }
}

@media screen and (max-width: 640px) {
    .lab_navbar_menu {
        padding: 0 0 ;
    }

    .contact-us_btn {
        display: none;
    }
}

@media screen and (max-width: 460px) {

    
}