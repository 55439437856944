.main_hero {
    width: 90%;
    max-width: 1366px;
    height: 90vh;
    margin: 0 auto;

}

.hero_content_holder_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.content {
    text-align: center;
    font-size: 64px;
    opacity: .4;
    line-height: 80px;
    margin-top: 20px;
}

.content:hover {
    transition: ease-in ease-out 1.5s;
    opacity: .7;
}

.hero_btn_holder {
    display: flex;
    justify-content: center;
}

.hero_btn {
    margin: 4em auto;
    padding: 15px 25px;
    color: white;
    border-radius: 30px;
    background: linear-gradient(black, black) padding-box, border-box var(--mainBg);
    border: 2px solid transparent;
    cursor: pointer;
    font-size: 1em;
}


.hero_btn:hover {
    box-shadow: 1px 1px 5px var(--thirdColor);
}

.scroll_animation {
    display: flex;
    flex-direction: column;
    margin-top: 150px;
}

.scroll_animation p {
    text-align: center;
    margin-top: .5rem;
    opacity: .8;
}

.scroll_down {
    height: .7rem;
    width: .7rem;
    margin: 0 auto;
    background: var(--mainBg);
    border-radius: 50%;
}

.scroll_down {
  animation: scroll_down 2s ease infinite;
}
@keyframes scroll_down {
  0% { transform: translateY(0); }
  20% {
    opacity: 1;
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

/* .our_services_popup_page {
    background: white;
    height: 70vh;
    width: 90%;
    margin: 1rem auto;
    color: black;
    
} */

/* @media screen and (min-width: 1080px) {
    .main_hero {
        padding-top: 10rem;
    }
} */

@media screen and (max-width: 1080px) {
    .content {
        font-size: 2.8rem;
    }
}

@media screen and (max-width: 800px) {
    .content {
    font-size: 2.2rem;
    margin-top: 100px;
    }

    .scroll_animation {
        margin-top: 50px;
    }
}

@media screen and (max-width: 700px) {
    .content {
    font-size: 34px;
    margin-top: 30px;
    line-height: 40px;
    display: flex;
    align-items: center;
    }
    .scroll_animation {
        margin-top: 30px;
    }
}

@media screen and (min-width: 700px) {
    .golden_ratio {
        display: none;
    }
}

@media (min-width:640px) and (max-width: 700px) {
    .golden_ratio {
        height: 250px;
    }

        .scroll_animation {
        margin-top: 20px;
    }
}

@media screen and (max-width: 640px) {
    .golden_ratio {
    display: block;
    height: 250px;
    }

    .content {
        font-size: 23px;
        margin: 0;
    }

    .scroll_animation {
        margin-top: 20px;
    }
}

@media screen and (max-width: 480px) {
    .main_hero {
        height: 80vh;
    }

    .hero_content_holder_container {
        height: 80vh;
    }
    
    .golden_ratio {
        /* margin-top: 1rem; */
        height: 250px;
        width: 250px;
        background-size: cover;
        object-fit: cover;
        opacity: .7;
    }

    .content {
        font-size: 1.5rem;
        opacity: .4;
    }

    .scroll_animation {
        margin-top: 15px;
    }
}