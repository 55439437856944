.login_btn{
    width: 105px;
    height: 47px;
    padding: 10px 20px;
    color: white;
    border-radius: 30px;
    background: linear-gradient(black, black) padding-box, border-box var(--mainBg);
    border: 2px solid transparent;
    cursor: pointer;  
    font-size: 1em;
}
.heading{
    color: white;
   margin-bottom: 20px;
   text-align: center;
}
.login-container{
    border-radius: 7%;
    justify-content: center;
    background: linear-gradient(45deg, #E10B7B 0%, #652D90 49.48%, #1C85C7 100%);
    width: 25%;
    margin-top: 50px;
    height: 55vh;
}