.admin-layout {
    display: flex;
    /* flex-direction: column; */
    height: 100vh; /* Ensure the layout takes up the full viewport height */
  }
  
  .layout-container {
    display: flex;
    flex: 1;
    flex-direction: column; /* Allow the content container to grow and occupy remaining space */
  }
  
  .sidebar {
    /* Apply fixed width and optional minimum width to the sidebar */
    width: 250px;
    min-width: 200px;
    /* Add other sidebar styles as needed */
  }

  .content-container{
    /* width: 100vw; */
    flex:1;
    overflow: auto;
  }