.main_works {
    width: 90%;
    max-width: 1366px;
    padding: 2.1rem 0;
    margin: 0 auto;
}

.works_title {
    font-size: 44px;
    font-weight: bold;
    opacity: .2;
    text-align: center;
    text-transform: uppercase;
}

.works_title:hover {
    transition: ease-in ease-out 1.5s;
    opacity: .7;
}

.card_container {
    display: flex;
    flex-wrap: wrap;
    padding: 3rem 0;
    width: 100%;
}

.cards {
    -ms-flex: 33.33%; /* IE10 */
  flex: 33.33%;
  max-width: 33.33%;
}

.images {
    width: 98%;
    opacity: .5;
    margin: .3rem;
}

.images:hover {
    opacity: 1;
}

.custom-work-prev-arrow {
    position: absolute;
    top: 10%;
    left: 0;
    transform: translateY(-50%);
    cursor: pointer;
  }

  .custom-work-next-arrow {
    position: absolute;
    top: 10%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
  }

.slick-dots li button:before {
  margin-top: 2rem;
  color: #d8d8d8;
}

.slick-dots li.slick-active button:before {
  color: #fff;  
}

.slick-prev:before,
.slick-next:before {
   display:none;
}
  

@media screen and (max-width: 960px) {
    .images {
        opacity: 1;
    }
}

@media screen and (max-width: 800px) {
  .cards {
    -ms-flex: 100%;
    flex: 100%;
    max-width: 100%;
  }
}

@media (min-width: 640px) {
    .responsive-slider {
        display: none;
    }
}

@media (max-width: 640px) {
    .card_container {
        display: none;
    }
    .responsive-slider {
        width: 100%;
        display: block;
        padding: 0;
        margin: 1.5rem auto;
    }
    .cards {
        display: flex!important;
        gap: 2;
    }

    .images {
        object-fit: cover;
        border-radius: 1rem;
        width: 95%;
        margin: 0 10px;
    }
}

 @media screen and (max-width: 480px) {
    .main_works{
        margin-top: 8rem;
        height: 425px;
    }
    .works_title {
        font-size: 1.5rem;
        margin-top: 4em;
    }
    
 }
