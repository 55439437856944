.main_footer {
    width: 90%;
    max-width: 1366px;
    margin: 0 auto;
    margin-top: 100px;
}

.footer_title {
    text-align: center;
    text-transform: uppercase;
    font-size: 44px;
    font-weight: bold;
    margin-top: 50px;
}

.footer_content {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0;
}

.footer_left {
    width: 60%;
    display: flex;
    flex-direction: column;
    
}

.footer_map_and_adresses {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footer_logo_left {
    display: flex;
    justify-content: flex-start;
}

.footer_mid {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    font-size: 20px;
    font-weight: medium;
}

.footer_mid a {
    margin: 10px 0;
    text-decoration: none;
    color: rgb(255, 255, 255);
}

.footer_mid a:hover {
    opacity: 1;
}

.footer_right {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    font-size: 20px;
    font-weight: medium;
}
.footer_right a {
    margin: 10px 0;
    text-decoration: none;
    color: rgb(255, 255, 255);
    opacity: .4;
}

.footer_right a:hover {
    opacity: 1;
}

.footer_button_box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_btn {
    /* background: var(--mainBg); */
    background: white;
    color: rgb(0, 0, 0);
    padding: .8rem 1.5rem;
    margin: 30px 0;
    border: none;
    border-radius: 30px;
    display: flex;
    align-items: center;

    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
}

.footer_btn:hover {
    box-shadow: -1px 1px 6px rgb(33, 148, 255);
}

.footer_btn .call_icon {
    margin-right: .3rem;
    color: #1caa50;
}

.hr_line {
    opacity: .2;
    height: 1px;
}

.footer_header {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
}

.copyright {
    display: flex;
    gap: 1rem;
}

.copyright a {
    color: white;
    text-decoration: none;
}

.copyright a:hover {
    opacity: .5;
}

.footer_address {
    margin-top: 30px;
    opacity: .7;
    display: flex;
    font-size: 1.2rem;
    font-weight: 400;
    align-items: center;

}

.footer_address 
.samakhushi_margin {
    margin-left: .5rem;
}

.footer_map_area {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25%;
    margin: 1.5rem 0;
}

.open_map {
    position: absolute;
    text-decoration: none;
    color: white;
    background: transparent;
    border:  1px solid rgba(255, 255, 255, 0);
    border-radius: 2rem;
    padding: .4rem .7rem;
    margin-top: .4rem;
    transition: border 1.5s;
}

.ktm_google_map_img {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    opacity: .5;
}

.open_map:hover, 
.open_map:active {
    cursor: pointer;
    border: 1px solid white;
}

.goUp {
    height: 30px;
    width: 30px;
    border-radius: 6px;
    background: var(--mainBg);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.goUp:hover {
    box-shadow: -1px 1px 6px rgb(33, 148, 255);
}

@media (max-width: 920px) {
    .open_map {
        font-size: .6rem;
    }

    .footer_mid,
    .footer_right {
        font-size: 16px;
    }

    .footer_address {
        font-size: 1rem;
    }
    .copyright {
        font-size: 14px;
    }
}


@media (max-width: 640px) { /*Responsive ------------------*/
    .footer_logo_left {
        justify-content: center;
    }

    .footer_title {
        font-size:  2rem;
    }

    .footer_address br {
        display:none ;
    }

    .footer_address {
        opacity: .9;
        justify-content: center;
    }

    .footer_address 
    .samakhushi_margin {
        margin-left: .5rem;
    }
    
    .footer_content {
        flex-direction: column;
        align-items: center;
    }
    .footer_header {
        flex-direction: column;
        align-items: center;
    }
    .copyright {
        flex-direction: column;
        text-align: center;
    }
    .goUp {
        display: none;
    }

    .footer_mid,
    .footer_right {
        width: 100%;
        justify-content: center;
        text-align: center;
        align-items: center;
        padding-top: 1rem;
        color: rgb(255, 255, 255);
    }
    
    .footer_map_area {
        width: 100%;
    }

    .ktm_google_map_img {
        width: 80%;
        height: 80%;
    }

    .open_map  {
        font-size: .8rem;
        border: 1px solid white;
    }

    .foobar_footer{
        display: none;
    }

    .footer_map_and_adresses {
        flex-direction: column;
    }
}

@media screen and (max-width: 460px) {
    .footer_title {
        font-size: 1.5rem;
    }
}